'use strict';

angular.module('enervexSalesappApp').factory('CustomerCompany', function ($resource) {
	return $resource('/api/legacy-proxy/customer-companies/:id/:controller', {
		id: '@_id'
	},
	{
		get: {
			method: 'GET',
			params: {
				id:'me'
			}
		},
		update: {
			method: 'PUT'
		}
	});
});
